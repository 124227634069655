<template>
  <div
    class="page songlist-page"
    v-log="{
      event_type: 'show',
      event_name: 30173,
      event_data: {
        str1: '歌手详情页',
        str2: '歌手详情页',
        str3: '进入歌手详情页',
        str4: 'show',
      }
    }"
  >
    <!-- <SearchBar :title="listSheet.name"></SearchBar> -->
    <SearchBar
      v-if="browserType === 'landscape' && !isSpecialSongList"
      :custom-style="{  marginLeft: '50px' }"
      :placeholder="`搜索${listSheet.name}金曲`"
      :title="listSheet.name"
      :isShowSearchBtn="true"
      :isShowRight="false"
      :isSearch="true"
      @search="handleSearch"
    />
    <template v-else>
      <HeadBar class="songlist-headbar" :title="listSheet.name" />
      <PureSearch
        class="songlist-bar-pure"
        :isSearch="true"
        :placeholder="`搜索${listSheet.name}金曲`"
        v-if="!isSpecialSongList"
        @search="handleSearch"
      />
      <div v-if="browserType !== 'landscape' && isSpecialSongList" class="songlist-headbar-title">特别定制 会员专享歌单</div>
    </template>

    <div class="list">
      <div class="list-left">
        <div v-if="browserType === 'landscape' && isSpecialSongList" class="list-left-title">特别定制 会员专享歌单</div>
        <LoadMore
          class="song-list"
          ref="loadMoreRef"
          v-if="dataList.length"
          @load-more="handleLoadMore"
          safeAreaHeight="15vw"
        >
          <!-- <div class="list-left-bg" v-if="singerhead">
            <img :src="singerhead" v-img-fallback="imgFallback" />
            <p>{{ listSheet.name }}</p>
          </div> -->
          <div 
            v-if="vip_cnt && !isVip"
            class="list-header"
            @click="handleOpenVipModal"
            v-log="{
              event_type: 'show',
              event_name: 30165,
              event_data: {
                str1: '歌手详情页',
                str2: '歌曲列表',
                str3: '会员文字链展示',
                str4: 'show',
                str5: isLogin ? '已登录' : '未登录',
                str7: userType,
              }
            }"
          >
          <!-- 含{{vip_cnt}}首VIP专属歌曲， -->
            <div class="left">开通会员畅享全部</div>
            <div class="right">VIP仅<span>0.2</span>元/天</div>
          </div>
          <SongItem
            v-for="(songItem, index) in dataList"
            :key="index"
            :songItem="songItem"
            :log-from="{
              song_list_source: listSheet.singerid ? 6 : 3,
              song_list: listSheet.name,
              song_list_id: listSheet.name,
            }"
            :ponitActionLog="ponitActionLog"
          />
        </LoadMore>
        <!-- <template v-else>
          <div class="list-left-bg" v-if="singerhead">
            <img :src="singerhead" v-img-fallback="imgFallback" />
            <p>{{ listSheet.name }}</p>
          </div>
          <div v-if="vip_cnt" class="list-header">
            <div class="left">含{{vip_cnt}}首VIP专属歌曲，开通会员畅享全部</div>
            <div class="right">VIP仅<span>0.2</span>元/天</div>
          </div>
          <div class="list-left-empty">
            <img src="https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png" alt="">
            <p>暂无歌曲</p>
          </div>
        </template> -->
        <div v-else>
          <div class="list-left-empty">
            <img src="https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png" alt="">
            <p>抱歉，暂无“{{listSheet.name}} {{keyword}}”结果</p>
          </div>
        </div>
      </div>
      <!-- <div class="list-right-bg" v-if="singerbg">
        <ProgressiveImage
          :placeholder-src="singerbg + '?imageView2/2/w/10'"
          :src="singerbg"
          class="singer-bg"
        />
        <p>{{ listSheet.name }}</p>
      </div> -->
      <!-- <div class="list-right" v-if="!onFirstLoad & !singerbg || isGedan">
        <img :src="listSheet.image" v-img-fallback="imgFallback" />
        <p :class="isGedan && 'gedan'">{{ listSheet.name }}</p>
      </div> -->
    </div>

    <LbBanner />

  </div>
</template>

<script>
import { ref, onBeforeMount, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import SongItem from '@/components/song-item/index.vue'
import LbBanner from '@/components/lb-banner/index.vue'
import PureSearch from '../search/components/search-bar/index.vue'

import { useShareBrowserSize } from '@/composables/sharedComposable'
import { getPlaylistDetail } from '@/service/playlist'
import { searchSinger, search } from '@/service/search'
import { getHotSongList } from '@/service/hotSongList'
import { sendLog } from '@/directives/v-log/log'
// import { ProgressiveImage } from 'vue-progressive-image';
import useVip from '@/composables/useVip'

export default {
  name: 'SongList',
  components: {
    SongItem,
    LbBanner,
    PureSearch
    // ProgressiveImage
    // TopFansModal,
  },
  activated() {
    const store = useStore()
    const { isUsed, position } = store.state.pageCacheData.playlist
    if (!isUsed) {
      this.handleInitData()
    } else {
      // 放在这里统一处理 - 防止记忆activated钩子与onBeforeMount刷新时加载重复
      this.fetchData()
      if (this.$refs.loadMoreRef) {
        this.$refs.loadMoreRef.root.scrollTop = position
      }
      this.handleUpdateCachePosition(false, 0)
    }
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const { browserType } = useShareBrowserSize()

    const { showVipQrcode } = useVip()
    const unionid = computed(() => store.state.userInfo.unionid)
    const isLogin = computed(() => !!unionid.value)
    const userType = computed(() => store.state.userInfo.userType)
    const isVip = computed(() => !!store.state.vipInfo.end_time)

    let loadMoreRef = ref(null)
    const dataList = ref([])
    let singerbg = ref('')
    let singerhead = ref('')
    let vip_cnt = ref(0)
    const imgFallback = {
      loading:
        'https://qncweb.ktvsky.com/20231226/vadd/9a350e743cc80ede01c2d3e4b7807eae.png',
      error:
        'https://qncweb.ktvsky.com/20231226/vadd/9a350e743cc80ede01c2d3e4b7807eae.png',
    }
    // const imgUserFallback = {
    //   loading: 'https://qncweb.ktvsky.com/20230803/vadd/004a58b7a4169842eb4f4bfaa23d277d.png',
    //   error: 'https://qncweb.ktvsky.com/20230803/vadd/004a58b7a4169842eb4f4bfaa23d277d.png'
    // }

    let listSheet = ref({
      name: '',
      image: '',
      singerid: '',
    })
    let p = 1
    let version = ref({
      current: '',
      latest: '',
    })
    let songType = ref('')
    let isRequest = false
    let isSearchRequest = false
    // let onFirstLoad = ref(true)
    let isGedan = ref(false)
    let keyword = ref('')

    const isSpecialSongList = computed(() => listSheet.value.name === '“特”别定制，会员专享歌单')

    // let topfans = ref({
    //   name: '',
    //   img: '',
    //   count: 0
    // })
    // let userSingerSingCount = ref(0)
    // let isShowTopFansModal = ref(false)

    const ponitActionLog = computed(() =>
      listSheet.value.singerid
        ? {
            event_type: '10000~50000',
            event_name: 10045,
            event_data: {
              str1: '歌星',
              str2: '歌手区',
              str3: '进入任意歌手',
              str4: 'click',
            },
          }
        : {}
    )

    const requestBussinessData = async () => {
      let responseData = []
      const { singerid, name } = listSheet.value
      if (singerid) {
        const { data } = await searchSinger(singerid, p)
        responseData = {
          data: data.song,
        }
        singerbg.value = ''
        singerhead.value = data.singerhead ? data.singerhead : ''
        vip_cnt.value = data.vip_cnt ? data.vip_cnt : 0
      } else {
        responseData = await getPlaylistDetail({
          p,
          type: name,
          version: version.value.latest,
        })
      }
      return responseData
    }

    // bad logic - 歌单列表 - 热门歌曲 - 特殊接口
    const requestHotSongData = async () => {
      const res = await getHotSongList({
        p,
        unionid: unionid.value,
        version: version.value.latest,
      })
      if (res.data.length) {
        if (p === 1 && res.version) {
          version.value = res.version
        }
        dataList.value = dataList.value
          .concat(res.data)
          .filter((v, i) => i < 100)
        console.log('dataList.value', dataList.value.length)
        p++
      }
    }

    const fetchData = async () => {
      if (isRequest) {
        return
      }
      isRequest = true

      if (listSheet.value.name === '热门歌曲，总有一首你会唱') {
        if (!dataList.value.length) await requestHotSongData()
        isRequest = false
        return
      }
      
      if (listSheet.value.name === '“特”别定制，会员专享歌单') {
        singerhead.value = route.query.image
        isGedan.value = true
      }

      const bussinessResponseData = await requestBussinessData()
      if (bussinessResponseData.data.length !== 0) {
        if (p === 1 && bussinessResponseData.version) {
          version.value = bussinessResponseData.version
        }
        dataList.value = dataList.value.concat(bussinessResponseData.data)
        p++
      }
      isRequest = false
      // if (onFirstLoad.value) onFirstLoad.value = false
    }

    const handleLoadMore = () => {
      if (keyword.value) {
        getSearchResult()
      } else {
        fetchData()
      }
    }

    // const handleOpenTopFansModal = () => {
    //   sendLog({
    //     event_type: 'click',
    //     event_name: 1734,
    //     event_data: {
    //       str1: '1',
    //     },
    //   })
    //   sendLog({
    //     event_type: '10000~50000',
    //     event_name: 10046,
    //     event_data: {
    //       str1: '歌星',
    //       str2: '歌手区',
    //       str3: '头号粉丝',
    //       str4: 'click',
    //     },
    //   })
    //   // 头号粉丝虚以待位时不可点击
    //   if (topfans.value.name === '') return
    //   // 未登录情况
    //   isShowTopFansModal.value = true
    //   if (unionid.value) requestTopFansData(unionid.value)
    // }

    // const handleCloseTopFansModal = () => {
    //   isShowTopFansModal.value = false
    // }

    // const requestTopFansData = async (unionid) => {
    //   const res = await getTopfans({
    //     singerid: listSheet.value.singerid,
    //     unionid: unionid ? unionid : ''
    //   })

    //   if (res.topfans.nickname) {
    //     topfans.value = {
    //       name: get(res, 'topfans.nickname', ''),
    //       img: get(res, 'topfans.headimgurl', ''),
    //       count: get(res, 'topfans.count', 0),
    //     }
    //     userSingerSingCount.value = res.usercount
    //   }
    // }

    const handleInitData = () => {
      dataList.value = []
      listSheet.value = route.query
      p = 1
      version.value = {
        current: '',
        latest: '',
      }
      songType = ''
      isRequest = false
      fetchData()
      // requestTopFansData()
    }

    const handleUpdateCachePosition = (u, v) => {
      store.commit('UPDATE_PAGE_CACHEDATA', {
        data: {
          isUsed: u,
          position: v,
        },
        type: 'playlist',
      })
    }

    const handleOpenVipModal = () => {
      sendLog({
        event_type: 'show',
        event_name: 30166,
        event_data: {
          str1: '歌手详情页',
          str2: '歌曲列表',
          str3: '会员文字链弹窗展示',
          str4: 'show',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        }
      })
      showVipQrcode({
        log: '歌星详情页会员文字链',
        isLogin: isLogin.value,
        fr: 1866, // 车机端直接扫码支付的埋点
      })
    }

    const handleSearch = (k) => {
      console.log('handleSearch')
      sendLog({
        event_type: 'show',
        event_name: 30283,
        event_data: {
          str1: '歌手详情页',
          str2: '搜索',
          str3: '点击',
          str4: 'click',
        }
      })
      // if (!k.trim()) {
        // showVipQrcode({
        //   log: '搜索页-搜索栏-空词搜索弹窗',
        //   isLogin: isLogin.value,
        //   fr: vipLogFrom.get('搜索页-搜索栏-空词搜索弹窗')
        // });
        // sendLog({
        //   event_type: 'show',
        //   event_name: 30191,
        //   event_data: {
        //     str1: '搜索页',
        //     str2: '搜索栏',
        //     str3: '空词搜索弹窗',
        //     str4: 'show',
        //     str5: isLogin.value ? '已登录' : '未登录',
        //   }
        // })
        // return
      // }
      keyword.value = k
    }

    const searchHandler = {
      song: async () => {
        const bussinessResponseData = await search(
          `${listSheet.value.name} ${keyword.value}`,
          p
        )
        // 搜索上报 10:搜索到结果，11:未搜索到结果，12:接口异常
        // sendLog({
        //   event_type: 'click',
        //   event_name: 122,
        //   event_data: {
        //     key_words: keyword.value,
        //     status: getSearchReportStatus(bussinessResponseData),
        //   },
        // })
        if (bussinessResponseData.song.length) {
          const songvipsort = bussinessResponseData.song // toSongvipSort(bussinessResponseData.song) - 转为服务端处理
          // setSearchSongList(songvipsort)
          dataList.value = dataList.value.concat(songvipsort)
          p++
        }
        isSearchRequest = false
        // sendLog({
        //   event_type: '10000~50000',
        //   event_name: 10021,
        //   event_data: {
        //     str1: '搜索页',
        //     str2: '搜索栏',
        //     str3: '点击搜索',
        //     str4: 'click',
        //   },
        // })
      },
    }

    const getSearchResult = async () => {
      if (isSearchRequest) {
        return
      }
      isSearchRequest = true
      searchHandler['song'].call()
    }

    watch(keyword, (k) => {
      if (k) {
        dataList.value = []
        p = 1
        getSearchResult()
      } else {
        handleInitData()
      }
    })

    onBeforeMount(() => {
      listSheet.value = route.query
      if (route.name === 'songList') {
        // 当页面是歌星的详情页时，不触发actived钩子，在这里处理
        fetchData()
        // requestTopFansData()
      }
    })

    onBeforeRouteLeave((to, from, next) => {
      if (to.name === 'search' && from.name === 'playList') {
        const position = loadMoreRef.value
          ? loadMoreRef.value.root.scrollTop
          : 0
        handleUpdateCachePosition(true, position)
      }
      next()
    })

    return {
      // onFirstLoad,
      imgFallback,
      // imgUserFallback,
      loadMoreRef,
      singerbg,
      singerhead,
      dataList,
      listSheet,
      ponitActionLog,
      songType,
      keyword,
      // topfans,
      // userSingerSingCount,
      // isShowTopFansModal,
      fetchData,
      handleInitData,
      handleUpdateCachePosition,
      // handleOpenTopFansModal,
      // handleCloseTopFansModal,
      vip_cnt,
      handleOpenVipModal,
      isLogin,
      userType,
      isVip,
      isGedan,
      browserType,
      handleSearch,
      isSpecialSongList,
      handleLoadMore,
    }
  },
}
</script>

<style lang="stylus" scoped>
.page
  background: #000000B2
  .search-bar
    background none
  .singer-bg
    position absolute
    top 110px
    right 0
    width 807px
    height auto
    z-index 0
  ::v-deep .v-progressive-image
    & > div
      padding-bottom 0!important
    .v-progressive-image-placeholder
      width 807px!important
      height auto
  .empty-wrapper
    margin 0 auto
  .empty
    color: rgba(255, 255, 255, 0.40);
    font-size 28px
    background url('https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png') no-repeat top center
    background-size 80px auto
    padding-top 100px
    text-align center
    margin 245px auto 0
    @media screen and (max-width 1200px)
      margin-top 409px
.infinite-loading
  display flex
  justify-content center
// .songlist-page-list
//   padding 0 150px 0 0
//   @media screen and (max-width 1200px)
//     padding 0
.songlist-page
  padding 0
  .list
    width 826px
    height 916px
    position fixed
    right 80px
    // top 164px
    top 145px
    display flex
    justify-content space-between
    @media screen and (max-width 1200px)
      width 1080px
      height 992px
      top 887px
      left 60px
      display flex
      flex-direction column
    &-right
      width 400px
      // padding-left 70px
      img
        width 400px
        height 400px
        margin-top 28px
        border-radius 50%
      p
        width 400px
        font-size 52px
        color rgba(209, 229, 237, 0.8)
        margin-top 159px
        text-align center
        &.gedan
          margin-top 90px
    &-right-bg
      p
        position absolute
        right 0
        bottom 235px
        width 807px
        font-size 52px
        color rgba(209, 229, 237, 0.8)
        margin-top 159px
        text-align center
      @media screen and (max-width 1200px)
        display none
    &-header
      width 826px !important
      display flex
      position sticky
      top 0
      // margin-left -20px
      @media screen and (max-width 1200px)
        width 100%
        margin-top 20px
        margin-left 0px
      width 980px
      height 80px
      background: linear-gradient(90deg, #E6C0A3 0%, #FEE4D3 100%, #FEE4D3 100%);
      border-radius: 10px;
      display flex
      justify-content space-between
      align-items center
      padding 0 20px 0 24px
      margin-bottom 20px
      .left
        padding-left 74px
        background url('https://qncweb.ktvsky.com/20240329/other/e8e4b97b61385a1f09f6b7ce2005d53a.png') left center no-repeat
        background-size 62px auto
        font-weight 700
        font-size 30px
      .right
        color #412F20
        font-size 28px
        background url('https://qncweb.ktvsky.com/20240329/other/a4c5989660b4dfa886e2486563581349.png') right center no-repeat
        background-size 26px auto
        padding-right 30px
        span
          color #DC302B
          font-weight 700
          padding 0 3px
    &-left
      // width 980px
      width 826px
      margin 0 !important
      padding 0 !important
      @media screen and (max-width 1200px)
        width 1080px
        height 100%
      &-bg
        display none
      &-title
        font-size 36px
        color rgba(219, 174, 106, 1)
        font-weight 500
        text-align left
        width 100%
        margin-bottom 20px
        line-height 1
      .song-list
        width 100% !important
        // padding 0 20px 110px 20px !important
        padding 0 !important
        height 100% !important
        ::v-deep .song
          height 141px !important
        @media screen and (max-width 1200px)
          padding 0 0 30px 0 !important
        &::-webkit-scrollbar
          display none
      .list-left-empty
        display flex
        flex-direction column
        justify-content center
        align-items center
        margin-top 24vh
        font-size 28px
        color rgba(255, 255, 255, 0.40)
        text-align center
        img
          width 80px
          height 80px
          margin-bottom 40px
        p
          height 32px
          line-height 32px
          margin-left 0
        @media screen and (max-width 1200px)
          margin-top 20vh
    @media screen and (max-width 1200px) and (min-height 1200px)
      flex-direction column
      justify-content center
      &-left
        width 100%
        display flex
        flex-direction column
        align-items center
        padding-left 0
        img
          width 200px
          height 200px
          margin-top 4px
        p
          margin-left 50px
          min-width 300px
        .song-list
          // padding 0 0 200px 0!important
        &-bg
          display flex
          flex-direction column
          justify-content center
          align-items center
          margin-top 20px
          img
            width 240px
            height 240px
            border-radius 50%
          p
            text-align center
            font-size: 32px;
            color: rgba(204, 230, 238, 0.80);
            margin 48px 0 18px
      &-right
        display none
        width 100%
        padding-left 0
        margin-top 8px
        .song-list
          height 76.6vh !important
    .hint
      text-align center
      color #555555
  .songlist-headbar
    background transparent
  .songlist-headbar-title
    font-size 36px
    color rgba(219, 174, 106, 1)
    font-weight 500
    position absolute
    top 775px
    left 0px
    text-align center
    width 100%
  .songlist-bar-pure
    background transparent
    position fixed
    top 775px
    left 0
    right 0
    bottom 0
</style>

