<template>
  <div class="page">
    <SearchBar title="唱过的歌"></SearchBar>
    <div class="list singing-list" v-if="isLogin && dataListNumber">
      <div class="list-left">
        <LoadMore
          class="song-list"
          ref="loadMoreRef"
          v-if="dataListNumber"
          @load-more="LoadMoreData"
          safeAreaHeight="11.6991vw"
        >
          <div class="inner-cover">
            <img :src="listSheet.image" v-img-fallback="imgFallback" />
            <p>{{ listSheet.name }}</p>
          </div>
          <template v-for="(songItem, index) in dataList" :key="index">
            <SongItem
              v-if="index < 100"
              :songItem="songItem"
              :log-from="{
                song_list_source: 5,
                str1: '2',
              }"
              :ponitActionLog="{
                event_type: '10000~50000',
                event_name: 10007,
                event_data: {
                  str1: '首页',
                  str2: '常唱',
                  str3: '进入常唱',
                  str4: 'click',
                },
              }"
            ></SongItem>
          </template>
        </LoadMore>
        <div v-else class="list-right-empty">
          {{ hintTxt }}
        </div>
      </div>
      <div class="list-right">
        <img :src="listSheet.image" v-img-fallback="imgFallback" />
        <p>{{ listSheet.name }}</p>
      </div>
    </div>
    <div class="guess-nodata" v-else>
      <div class="guess-nodata-top">
        <div class="guess-nodata-top-mask" v-if="!isLogin">
          <div class="guess-nodata-top-mask-button" @click.stop="handleShowVip">
            登录查看唱过的歌
          </div>
        </div>
        <p v-else>{{ hintTxt }}</p>
      </div>
      <GuessSonglist :pageSize="pageSize" renderType="block" showAllData />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import SongItem from '@/components/song-item/index.vue'
import GuessSonglist from '@/components/guess-song/songlist.vue'
import useVip from '@/composables/useVip'
import { getSingsingList } from '@/service/singing'
import { useStore } from 'vuex'
import { checkLandscapeOrPortrait } from '@/utils/device'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'Singing',
  components: {
    SongItem,
    GuessSonglist,
  },
  setup() {
    const store = useStore()
    const { showVipQrcode } = useVip()
    const unionid = computed(() => store.state.userInfo.unionid)
    const isLogin = computed(() => !!unionid.value)
    // 最多100条 - 需求紧 - 接口暂无分页逻辑
    let loadMoreRef = ref(null)
    const hintTxt = ref('')
    const dataList = ref([])
    const dataListNumber = computed(() => dataList.value.length)
    const imgFallback = {
      loading:
        'https://qncweb.ktvsky.com/20231212/vadd/d5d0736cbbe6b173599584f72c6b27f4.png',
      error:
        'https://qncweb.ktvsky.com/20231212/vadd/d5d0736cbbe6b173599584f72c6b27f4.png',
    }
    let listSheet = ref({
      name: '唱过的歌',
      image:
        'https://qncweb.ktvsky.com/20230706/vadd/711a2703123dc343441dfd7bd2e85d95.png',
    })
    let isRequest = false
    let pageSize = ref(6)

    const requestSingingData = async () => {
      let responseData = []
      responseData = await getSingsingList({
        unionid: unionid.value,
      })
      return responseData
    }

    const fetchData = async () => {
      if (!unionid.value) return
      if (isRequest) {
        return
      }
      isRequest = true
      const singingResponseData = await requestSingingData()
      dataList.value = singingResponseData
      isRequest = false
    }

    // 此处暂无分页
    const LoadMoreData = () => {
      console.log('没有更多数据了~')
    }

    const handleShowVip = () => {
      if (!isLogin.value) {
        showVipQrcode()
        sendLog({
          event_type: '10000~50000',
          event_name: 10007,
          event_data: {
            str1: '首页',
            str2: '常唱',
            str3: '进入常唱',
            str4: 'click',
            str9: '点击登录',
          },
        })
      }
    }

    watch(unionid, (val) => {
      if (val) {
        fetchData()
      }
    })

    onMounted(() => {
      fetchData()
      setTimeout(() => {
        hintTxt.value = '近一年没有点歌记录，快去点歌吧～'
      }, 800)
      pageSize.value = checkLandscapeOrPortrait() === 'landscape' ? 6 : 9
    })

    return {
      imgFallback,
      loadMoreRef,
      isLogin,
      dataList,
      dataListNumber,
      listSheet,
      hintTxt,
      pageSize,
      handleShowVip,
      fetchData,
      LoadMoreData,
    }
  },
}
</script>

<style lang="stylus" scoped>
.page
  background: #000000B2
  .search-bar
    background none
  @media screen and (max-width 1200px)
    padding-right 60px
    padding-left 78px
  .guess-nodata
    width 100%
    height auto
    &-top
      width 100%
      height 420px
      position relative
      display flex
      align-items center
      justify-content center
      @media screen and (max-width 1200px) and (min-height 1200px)
        height 452px
      p
        text-align center
        color: rgba(255, 255, 255, 0.40);
        font-size 28px
        background url(https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png) no-repeat top center
        background-size 90px auto
        padding-top 120px
        @media screen and (max-width 1200px)
          font-size 22px
          background-size 72px auto
      &-mask
        position absolute
        top 0
        right 0
        left 0
        bottom 0
        display flex
        justify-content center
        align-items center
        &-button
          width 380px
          height 100px
          text-align center
          line-height 100px
          color #fff
          font-size 32px
          border-radius 14px
          background rgba(255, 255, 255, 0.30)
.infinite-loading
  display flex
  justify-content center
.singing-list
  padding 0 150px 0 0
.list
  // padding 0 230px 0 80px
  display flex
  justify-content space-between
  &-right
    width 400px
    // padding-left 70px
    img
      width 400px
      height 400px
      margin-top 28px
      border-radius 4px
    p
      width 400px
      font-size 52px
      color: rgba(204, 230, 238, 0.80)
      margin-top 159px
      text-align center
  &-left
    width 980px
    margin 0 !important
    padding 0 !important
    .song-list
      width 100% !important
      padding 0 20px 110px 20px !important
      &::-webkit-scrollbar
        display none
    .inner-cover
      display none
    &-empty
      margin-top 30vh
      font-size 28px
      color rgba(255, 255, 255, 0.5)
      text-align center
  @media screen and (max-width 1200px) and (min-height 1200px)
    flex-direction column-reverse
    padding-right 0
    &-left
      width 100%
      flex 1
      display flex
      flex-direction row
      align-items center
      padding-left 0
      .song-list
        padding 0!important
      img
        width 200px
        height 200px
        margin-top 4px
      p
        margin-left 50px
        min-width 300px
    .inner-cover
      display block
      width 100%
      padding-left 0
      margin 20px auto 60px
      width 240px
      img
        width 240px
        height 240px
        margin 0
      .song-list
        height 76.6vh !important
      p
        font-size 32px
        color: rgba(204, 230, 238, 0.80)
        text-align center
        margin-top 48px
        min-width auto
        margin-left 0
    .list-right
      display none
</style>

